import React, { useState, useEffect } from "react"
import { YOUR_API_KEY } from "../../config"
import loadScript from "load-script"
import courseSvc from "../services/Course"
import Phase1Stats from "./ASDMHero/Phase1Stats"
import Phase2Stats from "./ASDMHero/Phase2Stats"

export default function ASDMHero(props) {
  const { partner } = require("../data.json")

  /*



  MAP



  */
  useEffect(async () => {
    const asdmLocationTrackingBatchId = 258

    try {
      const instructors = await courseSvc.fetchInstructorByBatchId(
        asdmLocationTrackingBatchId
      )
      const instructorIds = instructors.map(instructor => instructor.id)

      const user_locations = await courseSvc.fetchUserLocation(
        instructorIds.filter(item => [58, 59, 60].includes(item)) //Done to stop tracking of two other vans
      )

      const locationsArray = user_locations.courses_user_location.map(
        location => ({
          lat: location.location_latitude,
          lng: location.location_longitude,
        })
      )

      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${YOUR_API_KEY}`,
        () => {
          // The Google Maps API has loaded
          const map = new window.google.maps.Map(
            document.getElementById("map"),
            {
              center: { lat: 0, lng: 0 },
              zoom: 0,
            }
          )

          window.STMap = map

          const invisibleMarker = document.createElement("div")
          invisibleMarker.className = "opacity-100"

          const bounds = new window.google.maps.LatLngBounds()

          locationsArray.forEach(location => {
            // const marker = new window.google.maps.Marker({
            //   position: location,
            //   map: map,
            //   content: invisibleMarker,
            // })

            // bounds.extend(marker.getPosition())
            bounds.extend(location)

          })

          map.fitBounds(bounds)

          if (locationsArray.length === 1) {
            setTimeout(() => {
              map.setZoom(9)
            }, 1000)
          }
        }
      )
    } catch (err) {
      console.log(err)
      throw err
    }
  }, [])

  return (
    <div className="width-home2 h-full xl:block relative lg:pl-20">
      <div className="container my-16 px-4 mx-auto">
        <div className="mb-16 text-gray-800 text-center">
          <div
            className="grid grid-cols-2  items-center"
            style={{ marginTop: "17%" }}
          >
            <div className="">
              <img src={partner?.logo} style={{ width: "150px" }} />
            </div>
            <div className=" ">
              <h2 className="text-3xl font-bold display-6 text-orange">
                <h1>Skill Yatra 2023-24 Phase 2</h1>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div id="map" style={{ height: "200px", width: "100%" }}></div>
      <Phase2Stats />
      {/* <Phase1Stats /> */}
    </div>
  )
}
