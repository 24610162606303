const { makeGraphRequest } = require("../utils/request")

class InstructorService {
  getTotalInstructors() {
    return makeGraphRequest(`{
      courses_instructor_aggregate {
        aggregate{
            count
        }
      }
    }`).then(({ courses_instructor_aggregate: res }) => {
      return res.aggregate.count
    })
  }
}

const InstructorSvc = new InstructorService()

module.exports = InstructorSvc
