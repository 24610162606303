import React, { useCallback, useEffect, useRef, useState } from "react"
import Carousel, { Dots } from "@brainhubeu/react-carousel"
import "@brainhubeu/react-carousel/lib/style.css"
import ReactMarkdown from "react-markdown"

export default function NewImageSlider(props) {
  const [slides, setSlides] = useState(props.slides)
  const [plugins, setPlugins] = useState(props.plugins)

  useEffect(() => {
    let changed = false
    if (JSON.stringify(props.slides) !== JSON.stringify(slides))
      changed = true && setSlides(props.slides)
    if (JSON.stringify(props.plugins) !== JSON.stringify(plugins))
      changed = true && setPlugins(props.plugins)
  }, [props.slides, props.plugins])

  const [currentSlide, _setCurrentSlide] = useState(0)
  const setCurrentSlide = targetIndex => {
    console.log("Target index", targetIndex)
    if (targetIndex > slides.length - 1) targetIndex = 0
    if (targetIndex < 0) targetIndex = slides.length - 1
    if (props.onChange) props.onChange(targetIndex)
    _setCurrentSlide(targetIndex)
  }

  const smallSlides = slides.map(slide => (
    <div className="z-10 w-1/3 h-max p-3 center-box">
      <div className="bg-white rounded-md shadow-even h-max center-box">
        <div className="flex items-center border-b pt-2 px-4 justify-between">
          <div>
            <div className="text-lg red-dark">
              <ReactMarkdown>{slide.heading}</ReactMarkdown>
            </div>
            <div className="text-sm orange-light1 mt-1 mb-1">
              <ReactMarkdown>{slide.content}</ReactMarkdown>
            </div>
          </div>
          <img src={slide.subImage[0].url} className="ml-6 mb-4 h-12" />
        </div>
        <div className="py-2 px-4 text-sm text-gray-700">
          <ReactMarkdown>{slide.footer}</ReactMarkdown>
        </div>
      </div>
    </div>
  ))

  const intervalPeriod = 5000
  const updateSlider = useCallback(() => {
    setCurrentSlide(currentSlide + 1)
  }, [currentSlide])

  const intervalRef = useRef(null)

  useEffect(() => {
    clearInterval(intervalRef.current)
    intervalRef.current = setInterval(updateSlider, intervalPeriod)
    return () => clearInterval(intervalRef.current)
  }, [updateSlider])

  useEffect(() => {
    setTimeout(updateSlider, intervalPeriod)
  }, [])

  if (typeof window !== "undefined")
    return (
      <div className="relative">
        <Carousel
          value={currentSlide}
          onChange={setCurrentSlide}
          plugins={["arrows"]}
        >
          {slides.map(({ mainImage: [mainImage] }) => {
            return (
              <div className="slider flex items-center justify-center">
                <img
                  src={mainImage?.formats?.medium?.url || mainImage.url}
                  className="w-full "
                />
              </div>
            )
          })}
        </Carousel>

        {props.partner && slides.length !== 0 && (
          <div className=" bg-white absolute overflow-hidden shadow-even rounded-md z-10 w-40 max-h-40 min-h-fit min-w-fit p-2 center-box -ml-16 mt-[330px] object-fill">
            <img src={props.partner.logo} className="max-h-36" />
          </div>
        )}
        {props.partner && slides.length === 0 && (
          <div className=" bg-white overflow-hidden shadow-even rounded-md z-10 w-80 max-h-80 min-h-fit min-w-fit p-2 mt-24 ml-12 center-box object-fill">
            <img src={props.partner.logo} className="max-h-60" />
          </div>
        )}

        <div
          className="absolute overflow-hidden"
          style={
            props.partner
              ? {
                  zIndex: "10",
                  display: "inline-block",
                  width: "calc(50%)",
                  bottom: "-20px",
                  left: "150px",
                }
              : {
                  zIndex: "10",
                  display: "inline-block",
                  width: "calc(50%)",
                  top: "330px",
                  left: "-80px",
                }
          }
        >
          <div
            className="relative flex items-center"
            style={{
              width: "300%",
              transition: "1s",
              left: currentSlide * -100 + "%",
            }}
          >
            {smallSlides}
          </div>
        </div>
      </div>
    )
  else return ""
}
