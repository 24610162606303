const { makeGraphRequest } = require("../utils/request")

class UserService {
  getTotalUsers() {
    return makeGraphRequest(`{
      courses_moodle_user_stats {
        total_moodle_users
      }
    }`).then(({ courses_moodle_user_stats: res }) =>
      res.reduce((total, cur) => total + Number(cur.total_moodle_users), 0)
    )
  }
}

const userSvc = new UserService()

module.exports = userSvc
