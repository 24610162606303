import React, { useState, useEffect } from "react"
import { pythonBackendHost } from "../../../config"
import axios from "axios"
import { makeGraphRequest } from "../../utils/request"
import _ from "lodash"

export default function Phase2Stats(props) {
  const [reportsStat, setReportsState] = useState()
  useEffect(() => {
    axios
      .get(`${pythonBackendHost}/get_asdm_stats?from_date=2023-11-01`)
      .then(({ data }) => {
        setReportsState(data.data)
        return data.data
      })
      .catch(error => {
        // Handle errors
        console.error(error)
      })
  }, [])

  console.log("Reports data", reportsStat)

  const [tempSYInstitutesCount, setTempSYInstitutesCount] = useState(0)
  useEffect(() => {
    makeGraphRequest(
      `{
        courses_misc_tuples (
          where: {
            key: { _eq: "asdm_sy_p2_institutes" }
          }
        ) {
          value
        }
      }`,
      {}
    )
      .then(data => Number(_.get(data, "courses_misc_tuples.0.value") || "0"))
      .then(setTempSYInstitutesCount)
      .catch(console.error)
  }, [])

  const reportsStatForAtimeBeing = {
    districts: [
      "Dibrugarh",
      "Lakhimpur",
      "Dhemaji",
      "Nagaon",
      "Tinsukia",
      "Cachar",
      "Barpeta",
      "Sonitpur",
      "Golaghat",
      "Kokrajhar",
    ],
    total_districts: 10,
    total_schools: 26,
    students_counselled: 194,
    skill_yatra_tests_taken_in_schools: 15400,
    students_enroled_in_schools: 231,
  }

  return (
    <div className="container mt-16 px-4 mx-auto">
      <div id="stats-block" className="mb-8 text-gray-800 text-center">
        <div className="grid grid-cols-2 lg:grid-cols-2 gap-x-10 gap-y-4">
          <div className=" ">
            <h2 className="text-3xl font-bold display-6 text-orange">
              {tempSYInstitutesCount}
            </h2>
            <h5 className="text-sm py-2 font-medium ">
              Skill Yatra
              <br /> Schools/ Colleges
            </h5>
          </div>
          <div className="">
            <h2 className="text-3xl font-bold display-6">
              <span className=" text-blue-500">
                {reportsStatForAtimeBeing?.skill_yatra_tests_taken_in_schools}
              </span>
            </h2>
            <h5 className="text-sm py-2 font-medium ">
              Skill Yatra Tests Taken / Students Counselled
            </h5>
          </div>
          {/* <div className="">
        <h2 className="text-3xl font-bold display-6">
          <span className=" text-orange">
            {
              reportsStat?.skill_yatra_tests_taken_outside_schools
            }
          </span>
        </h2>
        <h5 className="text-sm py-2 font-medium">
          Skill Yatra Tests Taken outside Schools
        </h5>
      </div> */}
          {/* <div className="">
            <h2 className="text-3xl font-bold display-6 text-blue-500 ">
              {reportsStatForAtimeBeing.students_counselled}
            </h2>
            <h5 className="text-sm py-2 font-medium ">
              Students Counselled in
              <br /> Schools/ Colleges
            </h5>
          </div> */}

          {/* <div className="">
        <h2 className="text-3xl font-bold display-6">
          <span className=" text-blue-500">
            {reportsStat?.students_enroled_outside_schools}
          </span>
        </h2>
        <h5 className="text-sm py-2 font-medium ">
          Students enrolled outside Schools
        </h5>
      </div> */}
        </div>
        <div className="">
          <h2 className="text-3xl font-bold display-6">
            <span className=" text-orange">
              {reportsStat?.students_enroled_in_schools}
            </span>
          </h2>
          <h5 className="text-sm py-2 font-medium ">
            Students enrolled in
            <br /> Schools/ Colleges
          </h5>
        </div>
      </div>
      <div
        id="district-block"
        className="mb-8 border-4 bg-gray-200 p-4 rounded-xl flex justify-between"
      >
        <div className="mx-auto flex items-center justify-center flex-col">
          <h2 className="text-3xl font-bold display-6">
            <span className=" text-orange">
              {reportsStatForAtimeBeing?.total_districts}
            </span>
          </h2>
          <h5 className="py-2 text-lg font-semibold">Districts</h5>
        </div>
        <div className="flex items-center justify-center p-10 font-semibold text-gray-500 grid grid-cols-1 sm:grid-cols-4 gap-y-4">
          {reportsStatForAtimeBeing?.districts.map(district => {
            return <h5 className="mx-4 text-sm">{district}</h5>
          })}
        </div>
      </div>
    </div>
  )
}
