import React, { useState, useEffect } from "react"
import { pythonBackendHost } from "../../../config"
import axios from "axios"

export default function Phase1Stats(props) {
  const [reportsStat, setReportsState] = useState()
  useEffect(() => {
    axios
      .get(`${pythonBackendHost}/get_asdm_stats?to_date=2023-11-01`)
      .then(({ data }) => {
        setReportsState(data.data)
        return data.data
      })
      .catch(error => {
        // Handle errors
        console.error(error)
      })
  }, [])

  return (
    <div className="container mt-16 px-4 mx-auto">
      <div id="stats-block" className="mb-8 text-gray-800 text-center">
        <h2 className="text-3xl font-bold display-6 mb-10">
          <h1>Skill Yatra 2023-24 Phase 1</h1>
        </h2>
        <div className="flex flex-wrap gap-x-10 gap-y-4 justify-center">
          <div className=" ">
            <h2 className="text-3xl font-bold display-6 text-orange">
              {reportsStat?.total_schools}
            </h2>
            <h5 className="text-sm py-2 font-medium ">Skill Yatra Schools</h5>
          </div>
          <div className="">
            <h2 className="text-3xl font-bold display-6">
              <span className=" text-blue-500">
                {reportsStat?.skill_yatra_tests_taken_in_schools}
              </span>
            </h2>
            <h5 className="text-sm py-2 font-medium ">
              Skill Yatra Tests Taken in Schools
            </h5>
          </div>
          {/* <div className="">
        <h2 className="text-3xl font-bold display-6">
          <span className=" text-orange">
            {
              reportsStat?.skill_yatra_tests_taken_outside_schools
            }
          </span>
        </h2>
        <h5 className="text-sm py-2 font-medium">
          Skill Yatra Tests Taken outside Schools
        </h5>
      </div> */}
          {/* <div className="">
            <h2 className="text-3xl font-bold display-6 text-blue-500 ">
              {reportsStat ? Number(13777) : ""}
            </h2>
            <h5 className="text-sm py-2 font-medium ">Students Counselled</h5>
          </div> */}
          <div className="">
            <h2 className="text-3xl font-bold display-6">
              <span className=" text-orange">
                {reportsStat?.students_enroled_in_schools}
              </span>
            </h2>
            <h5 className="text-sm py-2 font-medium ">
              Students enrolled in Schools
            </h5>
          </div>
          {/* <div className="">
        <h2 className="text-3xl font-bold display-6">
          <span className=" text-blue-500">
            {reportsStat?.students_enroled_outside_schools}
          </span>
        </h2>
        <h5 className="text-sm py-2 font-medium ">
          Students enrolled outside Schools
        </h5>
      </div> */}
        </div>
      </div>
      <div
        id="district-block"
        className="mb-8 border-4 bg-gray-200 p-4 rounded-xl flex justify-center"
      >
        <div className="flex justify-center items-center flex-col">
          <h2 className="text-3xl font-bold display-6">
            <span className=" text-orange">{reportsStat?.total_districts}</span>
          </h2>
          <h5 className="py-2 text-lg font-semibold">Districts</h5>
        </div>
        <div className="flex items-center justify-start p-10 font-semibold text-gray-500 gap-y-4">
          {reportsStat?.districts.map(district => {
            return <h5 className="mx-4 text-sm">{district}</h5>
          })}
        </div>
      </div>
    </div>
  )
}
